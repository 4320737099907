import cityCityException from '../../cityCityException.Class'
import { buildRemarks } from './helpers'

const railGenders = {
  unknown: '001',
  male: '002',
  female: '003',
  mixed: '004',
}

const nameTitles = {
  herr: 'male',
  mr: 'male',
  fru: 'female',
  mrs: 'female',
  fröken: 'female',
  ms: 'female',
}

const getGender = (passenger) => {
  try {
    const genderTypes = ['male', 'female']
    if (!passenger) return 'unknown'
    if (
      typeof passenger?.gender === 'string' &&
      genderTypes.includes(passenger.gender.toLowerCase())
    ) {
      return passenger.gender.toLowerCase()
    }

    const titles = Object.keys(nameTitles)
    const replRegex = new RegExp(`\\s+(${titles.join('|')})\\s+`, 'i')
    let tmpName = ` ${passenger?.firstName || ''} `
    const nmMatch = tmpName.match(replRegex)
    const title = (nmMatch?.[1] || '').toLowerCase()
    return nameTitles[title] || 'unknown'
  } catch (e) {
    return 'unknown'
  }
}

const getGenderCode = (passengers) => {
  const genderByPassengers = {}
  const genders = []
  passengers.forEach((p) => {
    const tmpGender = getGender(p)
    if (!!tmpGender) genderByPassengers[p.uniqueId] = tmpGender
    if (!!tmpGender && !genders.includes(tmpGender)) genders.push(tmpGender)
  })
  const firstGenderKey =
    !railGenders?.[genders?.[0]] || genders?.[0] === 'unknown'
      ? 'mixed'
      : genders[0]
  return genders.length > 1 ? railGenders['mixed'] : railGenders[firstGenderKey]
}

/**
 * add berth accommodations
 * @param model
 * @param solution
 * @param accommodations (it will be mutated)
 * @param segment
 * @returns {boolean}
 */
const addBerthAccommodations = (model, solution, accommodations, segment) => {
  const rInd = segment.railidentifier
  const seatType = solution?.seatTypes?.[segment?.ID]
  const accomType = solution?.accomTypes?.[segment?.ID] || null
  const berthTypes = ['003', '005', '006']

  if (!rInd || !berthTypes.includes(accomType)) return false

  if (seatType === 'SlCouchette') {
    if (!accommodations[rInd]) accommodations[rInd] = {}
    accommodations[rInd]['general'] = {
      gender: { value: getGenderCode(model.passengers) },
      type: accomType,
    }
  } else if (seatType === 'SlShared') {
    model.passengers.forEach((p) => {
      const tmpGenderKey = getGender(p)
      const tmpGenderCode = railGenders[tmpGenderKey]
      const uId = p.uniqueId
      if (!tmpGenderCode || !uId || tmpGenderKey === 'unknown') return false
      if (!accommodations?.[rInd]) accommodations[rInd] = {}
      if (!accommodations[rInd][uId]) accommodations[rInd][uId] = {}
      accommodations[rInd][uId].gender = { value: tmpGenderCode }
      accommodations[rInd][uId].type = accomType
    })
  }

  return true
}

export const tktDeliveryExceptions = {
  SNA: 'email',
  NSB: 'email',
}

export const getDelivException = (checkout) => {
  const trainTrip = checkout?.items?.find((i) => i.type === 'Train')
  if (!trainTrip?.outboundTrip) return false
  const segments = [
    ...(trainTrip?.outboundTrip?.train?.segments || []),
    ...(trainTrip?.returnTrip?.train?.segments || []),
  ]

  for (let ind in segments) {
    const compCode = segments[ind]?.railserviceProvider?.Code || ''
    if (tktDeliveryExceptions?.[compCode])
      return tktDeliveryExceptions[compCode]
  }

  return null
}

export const getAvailableFop = (checkout) => {
  const { costfields = {}, fop } = checkout || {}
  const primaryPayment =
    costfields?.paymentMethods?.primaryPayment || 'profileCC'
  const rqFops = fop !== primaryPayment ? [primaryPayment] : []
  return { fops: [primaryPayment], rqFops }
}

export default (model, state, extData = {}) => {
  // model is the untouched cart type TRAIN
  // should return everything needed to complete a purchase
  const { seats, specificAccommodationSelected, outboundTrip, returnTrip } =
    model
  const { eticketDelivery } = extData || {}
  const { costFieldValues = {}, costfields } = state.checkout
  const { encloseCostfieldValues } = state.auth

  const bookingData = {
    totalPrice: model.totalPrice,
    eticketDelivery,
    correlationID: model.correlationID,
    outboundTrip: prepareTripData(model.outboundTrip),
    users: model.passengers.map((usr) => {
      if (!usr.mobile && eticketDelivery === 'phone') {
        throw new cityCityException({
          message: 'user have no phone',
          type: 'citycity warning',
          subtype: 'user.noPhone',
        })
      }
      const passCfData = {
        costFieldValues: costFieldValues?.[usr.uniqueId] || {},
        costfields,
      }
      return {
        firstName: usr.firstName,
        lastName: usr.lastName,
        email: usr.email,
        locator: usr.uniqueId,
        age: usr.age || 30,
        birthDate: usr.birthDate || null,
        isGuest: usr.isGuest || false,
        phone: usr.mobile || null,
        loyaltyProgram: usr.loyalty || null,
        remarks: buildRemarks(passCfData, encloseCostfieldValues),
      }
    }),
    loyaltyProgram: state.auth?.user?.loyaltyProgram,
  }

  if (model.returnTrip) {
    bookingData.returnTrip = prepareTripData(model.returnTrip)
  }

  const accommodations = {}
  let seatsExists = false
  for (let railInd in seats) {
    if (specificAccommodationSelected.includes(railInd) && seats[railInd]) {
      seatsExists = true
      accommodations[railInd] = { ...seats[railInd] }
    }
  }

  const oSegments = outboundTrip?.train?.segments || []
  oSegments.forEach((s) =>
    addBerthAccommodations(model, outboundTrip?.pricing, accommodations, s)
  )

  const rSegments = returnTrip?.train?.segments || []
  rSegments.forEach((s) =>
    addBerthAccommodations(model, returnTrip?.pricing, accommodations, s)
  )

  if (Object.keys(accommodations).length > 0) {
    bookingData.accommodations = accommodations
  }

  return bookingData
}

const prepareTripData = (trip) => {
  return {
    correlationID: trip.train.correlationID,
    tripUniqueKey: trip.train.tripUniqueKey,
    correlationCreateTime: trip.train?.correlationCreateTime || 0,
    scheduleSolution: { ...trip.train.scheduleSolution },
    segments: trip.train.segments.map((s) => {
      const addFields = trip.pricing?.segAddData?.[s?.ID]
      return { ...s, ...(addFields || {}) }
    }),
    solution: { ...trip.pricing, addFields: null },
  }
}
